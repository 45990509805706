import _axios from "axios";

import { ChatGptResumeRequestType } from "@/client/constants/query-keys";

import { ChatGptResponse } from "./change-tone";

const VITE_NETWORKIO_API_PATH =
  import.meta.env.VITE_NETWORKIO_API_PATH ?? "https://api.networkio.io";

export const fixGrammar = async (text: string, accessToken: string) => {
  const response = await _axios.post<ChatGptResponse>(
    `/resume/openai`,
    {
      request: ChatGptResumeRequestType.RESUME_GRAMMAR_FIX,
      text,
      params: [],
    },
    {
      baseURL: VITE_NETWORKIO_API_PATH,
      headers: {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (response.data.error) {
    throw new Error(
      // eslint-disable-next-line lingui/no-unlocalized-strings
      `No more tokens to use. Your balance will be refilled after ${response.data.error.message} days`,
    );
  }

  return {
    result: response.data.result ?? text,
    tokensLeft: response.data.tokensLeft,
  };
};
