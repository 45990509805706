import _axios from "axios";

import { ChatGptResumeParams, ChatGptResumeRequestType } from "@/client/constants/query-keys";

const VITE_NETWORKIO_API_PATH =
  import.meta.env.VITE_NETWORKIO_API_PATH ?? "https://api.networkio.io";

type Mood = "casual" | "professional" | "confident" | "friendly";

export type ChatGptResponse = {
  result?: string;
  tokensLeft?: number;
  error?: {
    name: string;
    message?: string;
  };
};

export const changeTone = async (text: string, mood: Mood, accessToken: string) => {
  const response = await _axios.post<ChatGptResponse>(
    `/resume/openai`,
    {
      request: ChatGptResumeRequestType.RESUME_TONE_CHANGE,
      text,
      params: [
        {
          key: ChatGptResumeParams.ChatGptMoodTone,
          value: mood,
        },
      ],
    },
    {
      baseURL: VITE_NETWORKIO_API_PATH,
      headers: {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (response.data.error) {
    throw new Error(
      // eslint-disable-next-line lingui/no-unlocalized-strings
      `No more tokens to use. Your balance will be refilled after ${response.data.error.message} days`,
    );
  }

  return {
    result: response.data.result ?? text,
    tokensLeft: response.data.tokensLeft,
  };
};
