import { createZodDto } from "nestjs-zod/dto";
import { z } from "zod";

export const networkioUserSchema = z.object({
  email: z.string().email(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  role: z.enum(["GLOBAL_ADMIN", "ADMIN", "MODERATOR", "CUSTOMER", "COACH", "PROMOTER"]),
  avatarSrc: z.string().optional(),
  languages: z.array(z.string()).optional(),
  isFellow: z.boolean(),
  chatGptTokensLeft: z.number().nullable().optional(),
  chatGptTokensPeriodStart: z.date().nullable().optional(),
  nwAccessToken: z.string().nullable().optional(),
});

export class NetworkioUserDto extends createZodDto(networkioUserSchema) {}
