import { TooltipProvider } from "@networkio-resume/ui";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import { Outlet } from "react-router-dom";

import { helmetContext } from "../constants/helmet";
import { queryClient } from "../libs/query-client";
import { AuthRefreshProvider } from "./auth-refresh";
import { DialogProvider } from "./dialog";
import { LocaleProvider } from "./locale";
import { ThemeProvider } from "./theme";
import { Toaster } from "./toaster";

export const Providers = () => {
  return (
    <GoogleOAuthProvider
      clientId={"204874967375-r8ti7mfd35bu6tbgl75m2ollecqae89q.apps.googleusercontent.com"}
    >
      <LocaleProvider>
        <HelmetProvider context={helmetContext}>
          <QueryClientProvider client={queryClient}>
            <AuthRefreshProvider>
              <ThemeProvider>
                <TooltipProvider>
                  <DialogProvider>
                    <Outlet />

                    <Toaster />
                  </DialogProvider>
                </TooltipProvider>
              </ThemeProvider>
            </AuthRefreshProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </LocaleProvider>
    </GoogleOAuthProvider>
  );
};
