import { createZodDto } from "nestjs-zod/dto";
import { z } from "nestjs-zod/z";

export const loginSchema = z
  .object({
    identifier: z.string(),
    password: z.password().min(6),
  })
  .refine(
    (value) => {
      return z.string().email().parse(value.identifier);
    },
    { message: "InvalidCredentials" },
  );

export const loginViaGoogleSchema = z.object({
  token: z.string(),
});

export class LoginDto extends createZodDto(loginSchema) {}
export class LoginViaGoogleDto extends createZodDto(loginViaGoogleSchema) {}
