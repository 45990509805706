import { t } from "@lingui/macro";
import { ErrorMessage } from "@networkio-resume/utils";

export const translateError = (error: ErrorMessage) => {
  switch (error) {
    case ErrorMessage.InvalidCredentials: {
      return t`It doesn't look like a user exists with the credentials you provided.`;
    }
    case ErrorMessage.UserAlreadyExists: {
      return t`A user with this email address and/or username already exists.`;
    }
    case ErrorMessage.SecretsNotFound: {
      return t`User does not have an associated 'secrets' record. Please report this issue on info@networkio.io.`;
    }
    case ErrorMessage.OAuthUser: {
      return t`This email address is associated with an OAuth account. Please sign in with your OAuth provider.`;
    }
    case ErrorMessage.InvalidBrowserConnection: {
      return t`There was an error connecting to the browser. Please make sure 'chrome' is running and reachable.`;
    }
    case ErrorMessage.ResumeSlugAlreadyExists: {
      return t`A resume with this slug already exists, please pick a different unique identifier.`;
    }
    case ErrorMessage.ResumeNotFound: {
      return t`It looks like the resume you're looking for doesn't exist.`;
    }
    case ErrorMessage.ResumeLocked: {
      return t`The resume you want to update is locked, please unlock if you wish to make any changes to it.`;
    }
    case ErrorMessage.ResumePrinterError: {
      return t`Something went wrong while printing your resume. Please try again later or raise an issue on GitHub.`;
    }
    case ErrorMessage.ResumePreviewError: {
      return t`Something went wrong while grabbing a preview your resume. Please try again later or raise an issue on GitHub.`;
    }
    case ErrorMessage.SomethingWentWrong: {
      return t`Something went wrong while processing your request. Please try again later or raise an issue on GitHub.`;
    }

    default: {
      return null;
    }
  }
};
