import { AuthResponseDto, LoginDto, LoginViaGoogleDto } from "@networkio-resume/dto";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { axios } from "@/client/libs/axios";
import { queryClient } from "@/client/libs/query-client";
import { useAuthStore } from "@/client/stores/auth";

export const login = async (data: LoginDto) => {
  const response = await axios.post<AuthResponseDto, AxiosResponse<AuthResponseDto>, LoginDto>(
    "/auth/login",
    data,
  );

  return response.data;
};

export const loginWithGoogle = async (data: LoginViaGoogleDto) => {
  console.log("loginWithGoogle");

  try {
    const response = await axios.post<
      AuthResponseDto,
      AxiosResponse<AuthResponseDto>,
      LoginViaGoogleDto
    >("/auth/google/login", data);

    // eslint-disable-next-line lingui/no-unlocalized-strings
    console.log("loginWithGoogle response:", response);

    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error));
    throw new Error("error");
  }
};

export const useLogin = () => {
  const setUser = useAuthStore((state) => state.setUser);

  const {
    error: googleError,
    isPending: googleLoading,
    mutateAsync: loginViaGoogleFn,
  } = useMutation({
    mutationFn: loginWithGoogle,
    onError: (err) => {
      console.log(JSON.stringify(err));
    },
    onSuccess: (data) => {
      console.log("serverCall.onSuccess");
      setUser(data.user);
      queryClient.setQueryData(["user"], data.user);
    },
  });

  const {
    error,
    isPending: loading,
    mutateAsync: loginFn,
  } = useMutation({
    onError: (err) => {
      console.log(JSON.stringify(err));
    },
    mutationFn: login,
    onSuccess: (data) => {
      setUser(data.user);
      queryClient.setQueryData(["user"], data.user);
    },
  });

  return {
    login: loginFn,
    loading,
    error,
    googleError,
    googleLoading,
    loginViaGoogle: loginViaGoogleFn,
  };
};
