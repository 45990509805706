import { t } from "@lingui/macro";
import { Button } from "@networkio-resume/ui";
import { GoogleLogo } from "@phosphor-icons/react";
import { useGoogleLogin } from "@react-oauth/google";

import { useLogin } from "@/client/services/auth";
import { useAuthProviders } from "@/client/services/auth/providers";

export const SocialAuth = () => {
  const { providers } = useAuthProviders();

  const { loginViaGoogle } = useLogin();

  const login = useGoogleLogin({
    onError: (err) => {
      console.log(JSON.stringify(err));
    },
    onSuccess: async (tokenResponse) => {
      console.log("onSuccess");
      await loginViaGoogle({ token: tokenResponse.access_token });
    },
    onNonOAuthError: (err) => {
      console.log(JSON.stringify(err));
    },
    // eslint-disable-next-line lingui/no-unlocalized-strings
    scope: "openid email",
  });

  if (!providers || providers.length === 0) return null;

  return (
    <div>
      {providers.includes("google") && (
        <Button
          asChild
          className="w-full bg-secondary-accent hover:bg-secondary-accent/90"
          variant={"secondary"}
          size="lg"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            try {
              login();
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <div>
            <GoogleLogo className="mr-3 size-4" />
            {t`Google`}
          </div>
        </Button>
      )}
    </div>
  );
};
