import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { useLocalStorage, useMediaQuery } from "usehooks-ts";

const COLOR_SCHEME_QUERY = "(prefers-color-scheme: dark)";

type Theme = "light";

type UseThemeOutput = {
  theme: Theme;
  isDarkMode: boolean;
};

export const useTheme = (): UseThemeOutput => {
  const isDarkMode = false;
  const [theme, setTheme] = useLocalStorage<Theme>("theme", "light");

  return {
    theme,
    isDarkMode,
  };
};
