import { t } from "@lingui/macro";
import { Button } from "@networkio-resume/ui";
import dayjs from "dayjs";

import { USER_KEY } from "@/client/constants/query-keys";
import { fetchUser } from "@/client/services/user";
import { useQuery } from "@tanstack/react-query";

export const OpenAISettings = () => {
  const {
    error,
    isPending: loading,
    data: user,
  } = useQuery({
    queryKey: [USER_KEY],
    queryFn: fetchUser,
    refetchOnMount: "always",
    staleTime: 0,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  console.log("OpenAISettings");

  const tokensLeft = user?.chatGptTokensLeft ?? 0;
  const periodStart = user?.chatGptTokensPeriodStart
    ? dayjs(user.chatGptTokensPeriodStart).format("LL")
    : null;

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-2xl font-bold leading-relaxed tracking-tight">{t`OpenAI Tokens`}</h3>
        <p className="leading-relaxed opacity-75">
          {t`You can make use of the OpenAI API to help you generate content, or improve your writing while composing your resume.`}
        </p>
      </div>

      {Boolean(periodStart && tokensLeft) && (
        <div className="flex items-baseline justify-between">
          <div>
            <p className="text-2xl">{tokensLeft}</p>
            <p className="text-base font-semibold">{t`tokens`}</p>

            <p className="mt-2 text-right text-sm">{periodStart}</p>
          </div>

          <div>
            {!user?.isFellow && (
              <a href={"https://www.networkio.io/ru/subscribe"} target="_blank" rel="noreferrer">
                <Button>{t`Obtain Networkio Membership`}</Button>
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
