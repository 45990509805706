// Languages
export type Language = {
  id: string;
  name: string;
  locale: string;
  editorCode: string;
  progress?: number;
};

export const languages: Language[] = [
  {
    id: "bg",
    name: "Bulgarian",
    editorCode: "bg",
    locale: "bg-BG",
  },
  {
    id: "cs",
    name: "Czech",
    editorCode: "cs",
    locale: "cs-CZ",
  },
  {
    id: "el",
    name: "Greek",
    editorCode: "el",
    locale: "el-GR",
  },
  {
    id: "en-US",
    name: "English",
    editorCode: "en",
    locale: "en-US",
  },
  {
    id: "he",
    name: "Hebrew",
    editorCode: "he",
    locale: "he-IL",
  },
  {
    id: "it",
    name: "Italian",
    editorCode: "it",
    locale: "it-IT",
  },
  {
    id: "lv",
    name: "Latvian",
    editorCode: "lv",
    locale: "lv-LV",
  },
  {
    id: "lt",
    name: "Lithuanian",
    editorCode: "lt",
    locale: "lt-LT",
  },
  {
    id: "no",
    name: "Norwegian",
    editorCode: "no",
    locale: "no-NO",
  },
  {
    id: "pl",
    name: "Polish",
    editorCode: "pl",
    locale: "pl-PL",
  },
  {
    id: "pt-BR",
    name: "Portuguese, Brazilian",
    editorCode: "ptbr",
    locale: "pt-BR",
  },
  {
    id: "ro",
    name: "Romanian",
    editorCode: "ro",
    locale: "ro-RO",
  },
  {
    id: "ru",
    name: "Russian",
    editorCode: "ru",
    locale: "ru-RU",
  },
  {
    id: "uz",
    name: "Uzbek",
    editorCode: "uz",
    locale: "uz-UZ",
  },
  {
    id: "sv-SE",
    name: "Swedish",
    editorCode: "sv",
    locale: "sv-SE",
  },
];
