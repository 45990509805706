import { idSchema } from "@networkio-resume/schema";
import { createZodDto } from "nestjs-zod/dto";
import { z } from "nestjs-zod/z";

export const userSchema = z.object({
  id: idSchema,
  name: z.string().min(1).max(255),
  picture: z.literal("").or(z.null()).or(z.string().url()),
  email: z.string().email(),
  locale: z.string().default("en-US"),
  provider: z.enum(["email", "google"]).default("email"),
  createdAt: z.date().or(z.dateString()),
  updatedAt: z.date().or(z.dateString()),
  role: z.enum(["GLOBAL_ADMIN", "ADMIN", "MODERATOR", "CUSTOMER", "COACH", "PROMOTER"]),
  isFellow: z.boolean(),
  lastSignedIn: z.date().nullable().optional(),
  refreshToken: z.string().nullable().optional(),
  chatGptTokensLeft: z.number().nullable().optional(),
  chatGptTokensPeriodStart: z.date().nullable().optional(),
  nwAccessToken: z.string().nullable().optional(),
});

export class UserDto extends createZodDto(userSchema) {}
