import { QueryKey } from "@tanstack/react-query";

export const USER_KEY: QueryKey = ["user"];
export const AUTH_PROVIDERS_KEY: QueryKey = ["auth", "providers"];

export const LANGUAGES_KEY: QueryKey = ["translation", "languages"];

export const RESUME_KEY: QueryKey = ["resume"];
export const RESUMES_KEY: QueryKey = ["resumes"];
export const RESUME_PREVIEW_KEY: QueryKey = ["resume", "preview"];

export enum ChatGptResumeRequestType {
  RESUME_TONE_CHANGE = "RESUME_TONE_CHANGE",
  RESUME_GRAMMAR_FIX = "RESUME_GRAMMAR_FIX",
  RESUME_WRITING_IMPROVEMENT = "RESUME_WRITING_IMPROVEMENT",
}

export enum ChatGptResumeParams {
  ChatGptMoodTone = "ChatGptMoodTone",
}
