import { createZodDto } from "nestjs-zod/dto";

import { userSchema } from "./user";

export const updateUserSchema = userSchema.partial().pick({
  name: true,
  locale: true,
  picture: true,
  nwAccessToken: true,
  chatGptTokensLeft: true,
  chatGptTokensPeriodStart: true,
});

export class UpdateUserDto extends createZodDto(updateUserSchema) {}
