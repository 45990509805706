import { useTheme } from "@networkio-resume/hooks";
import { cn } from "@networkio-resume/utils";

type Props = {
  size?: number;
  className?: string;
};

export const Logo = ({ size = 32, className }: Props) => {
  const { isDarkMode } = useTheme();

  let src = "";

  switch (isDarkMode) {
    case false: {
      src = "/logo/logo.png";
      break;
    }
    case true: {
      src = "/logo/logo.png";
      break;
    }
    default: {
      break;
    }
  }

  return src ? (
    <img
      src={src}
      width={`${size}px`}
      alt="Networkio Resume"
      className={cn("rounded-sm", className)}
    />
  ) : null;
};
